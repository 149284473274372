/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file
import './scss/frontend.scss';
import AOS from 'aos';

import infiniteMarquee from 'ohjs-infinite-marquee';

document.querySelectorAll('[data-infinite-marquee]').forEach((el) => {
  infiniteMarquee(el, el.dataset.infiniteMarquee);
});

require('bootstrap/js/dist/carousel');
require('bootstrap/js/dist/collapse');
require('bootstrap/js/dist/modal');

const OHMedia = {};

OHMedia.Projects = require('./js/projects.js').default;

// make things available globally
window.OHMedia = OHMedia;

document.addEventListener('DOMContentLoaded', function () {
  AOS.init({
    duration: 800,
    once: true,
    disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
  });

  const consoleCatGraphic = `
   ▄▄▄▄▄,                       ,▄▄▄▄▄
  ▐═ ,,   ─╙▀▀▄Æ▀▀▀╙╙╙╙╙▀▀W▄▀▀╙─   ,, ═▌
  ╟─ ╫ ─└╙█▀                  ▀█╙└─ ╫ ─╟
  ▐▌ █ ,▓▀                      ▀▓, █ ▐▌
   █ ▐█▀                          ▀█▌ █
   ▀▄                                ▄▀
    █   ,▄▀▀▀▀▄,          ,▄▀▀▀▀▄,   █
   ▐▌  █ ▄▀▀▀▀▄ █   ▐▌   █ ▄▀▀▀▀▄ █  ▐▌
   █  ▐▌▐▌    ▐▌▐▌  ██  ▐▌▐▌    ▐▌▐▌  █
   █   █ ▀▄▄▄▄▀ █  █  █  █ ▀▄▄▄▄▀ █   █
   ▐    ▀#▄▄▄▄#▀ ▄▀    ▀▄ ▀#▄▄▄▄#▀    ▌
    █         ,▄▀ ▄▀▀▀▀▄ ▀▄,         █
    ▀█æ▄▄æW▀▀╙ ,   ▀▄▄▀   , ╙▀▀Wæ▄▄æ█▀
      █        ▀▄  ▄██▄  ▄▀        █
       ▀▄        ▀▀    ▀▀        ▄▀
         ╙▀▄,                ,▄▀╙
             ▀▀æ▄▄,    ,▄▄æ▀▀
                   ▀▀▀▀
  `;

  const consoleMessage = `
  OH! Hi... Interested in working with us?
  Send your resume and cover letter to hello@ohmedia.ca
  `;

  console.log(consoleCatGraphic + consoleMessage);
});
