import BootstrapModal from 'bootstrap/js/dist/modal';

export default function (baseHref, modals) {
  function open(modal, href) {
    if (!modal) {
      return;
    }

    BootstrapModal.getOrCreateInstance(modal).show();

    window.history.replaceState({}, '', href);
  }

  modals.forEach((modal) => {
    modal.addEventListener('hide.bs.modal', () => {
      window.history.replaceState({}, '', baseHref);
    });
  });

  return {
    open,
  };
}
