import ProjectNavigation from './project-navigation.js';
import getOffsetTop from './offset-top.js';
import Pagination from './pagination.js';

const PREFIX = 'p';

export default function ({
  baseHref,
  projectsDom,
  projectListing,
  industriesData,
  featuresData,
  industryCheckboxes,
  featureCheckboxes,
  paginationContainer,
  perPage,
  projectModals,
}) {
  const projectNavigation = new ProjectNavigation(baseHref, projectModals);

  industryCheckboxes = Array.from(industryCheckboxes);
  featureCheckboxes = Array.from(featureCheckboxes);

  let offsetTop;

  function setOffsetTop() {
    offsetTop = getOffsetTop(projectListing);
  }

  setOffsetTop();

  let projects = {};
  let filteredProjects = [];

  projectsDom.forEach((project) => {
    projects[PREFIX + project.dataset.project] = project;
    filteredProjects.push(project);
  });

  function intersect(arr1, arr2) {
    return arr1.filter((x) => arr2.includes(x));
  }

  function checkedValues(checkboxes) {
    return checkboxes.filter((i) => i.checked).map((i) => i.value * 1);
  }

  function filterProjects() {
    const industriesChecked = checkedValues(industryCheckboxes);

    const featuresChecked = checkedValues(featureCheckboxes);

    let projectsToShow = [];

    industriesChecked.forEach((industryId) => {
      projectsToShow = projectsToShow.concat(
        industriesData[industryId].projects
      );
    });

    featuresChecked.forEach((featureId) => {
      projectsToShow = projectsToShow.concat(featuresData[featureId].projects);
    });

    // array unique
    projectsToShow = [...new Set(projectsToShow)];
    filteredProjects = [];

    for (let key in projects) {
      const projectDom = projects[key];

      const regex = new RegExp('^' + PREFIX);

      const id = key.replace(regex, '');

      if (!projectsToShow.length || projectsToShow.includes(id)) {
        // add to array for pagination
        filteredProjects.push(projectDom);
      } else {
        projectDom.style.display = 'none';
      }
    }

    pagination.reset(filteredProjects.length);

    renderProjects(false);
  }

  function renderProjects(scrollTop = true) {
    filteredProjects.forEach((projectDom, i) => {
      projectDom.style.display = pagination.showIndex(i) ? '' : 'none';
    });

    if (scrollTop) {
      document.documentElement.scrollTop = offsetTop;
      document.body.scrollTop = offsetTop;
    }
  }

  const pagination = new Pagination(paginationContainer, {
    perPage: perPage,
    itemCount: filteredProjects.length,
    onPageChange: renderProjects,
  });

  renderProjects(false);

  industryCheckboxes.forEach((industry) => {
    industry.addEventListener('change', filterProjects);
  });

  featureCheckboxes.forEach((feature) => {
    feature.addEventListener('change', filterProjects);
  });

  window.addEventListener('resize', setOffsetTop, { passive: true });

  return {
    openModal(modal, href) {
      projectNavigation.open(modal, href);
    },
  };
}
