export default function (
  container,
  { perPage = 9, currentPage = 0, itemCount, onPageChange }
) {
  if (0 > currentPage) {
    currentPage = 0;
  }

  let pages;

  function setPages(itemCount) {
    pages = Math.ceil(itemCount / perPage);
  }

  setPages(itemCount);

  const prev = document.createElement('button');
  prev.textContent = 'Previous';
  prev.ariaLabel = 'Previous Page';
  prev.classList.add('prev');

  prev.addEventListener('click', function (e) {
    e.preventDefault();

    if (prev.disabled) {
      return;
    }

    currentPage--;

    render();

    onPageChange();
  });

  const next = document.createElement('button');
  next.textContent = 'Next';
  next.ariaLabel = 'Next Page';
  next.classList.add('next');

  next.addEventListener('click', function (e) {
    e.preventDefault();

    if (next.disabled) {
      return;
    }

    currentPage++;

    render();

    onPageChange();
  });

  function render() {
    container.replaceChildren();

    if (perPage >= itemCount) {
      return;
    }

    const pages = Math.ceil(itemCount / perPage);

    prev.disabled = 0 === currentPage;
    next.disabled = pages - 1 === currentPage;

    container.appendChild(prev);

    for (let i = 0; pages > i; i++) {
      const page = document.createElement('button');
      page.textContent = i + 1;
      page.dataset.page = i;
      page.ariaLabel = 'Page ' + (i + 1);
      page.disabled = i === currentPage;
      page.classList.toggle('active', i === currentPage);

      page.addEventListener('click', function (e) {
        e.preventDefault();

        currentPage = this.dataset.page * 1;

        render();

        onPageChange();
      });

      container.appendChild(page);
    }

    container.appendChild(next);
  }

  render();

  return {
    reset(newItemCount) {
      currentPage = 0;
      itemCount = newItemCount;

      render();
    },
    getCurrentPage() {
      return currentPage;
    },
    showIndex(index) {
      const minIndex = perPage * currentPage;
      const maxIndex = minIndex + perPage;

      return index >= minIndex && maxIndex > index;
    },
  };
}
