export default function (el) {
  let offsetTop = 0;

  while (el) {
    offsetTop += el.offsetTop;
    el = el.offsetParent;
  }

  return offsetTop;
}
